<template>
  <div id="baseInfo">
    <el-dialog
      :title="baseInfoFormTitle"
      width="680px"
      :visible.sync="baseInfoDialogVisible"
      :close-on-click-modal="false"
      @close="baseInfoDialogClose"
    >
      <el-form
        ref="baseInfoFormRef"
        :model="baseInfoForm"
        :rules="baseInfoFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="设施名称" prop="deviceName">
              <el-input v-model="baseInfoForm.deviceName" placeholder="请输入设施名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格型号" prop="spec">
              <el-input v-model="baseInfoForm.spec" placeholder="请输入规格型号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产厂家" prop="manufacturer">
              <el-input v-model="baseInfoForm.manufacturer" placeholder="请输入生产厂家" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出厂编号" prop="serialNumber">
              <el-input v-model="baseInfoForm.serialNumber" placeholder="请输入出厂编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="厂内编号" prop="deviceNo">
              <el-input v-model="baseInfoForm.deviceNo" placeholder="请输入编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="进厂日期" prop="enterFactoryDate">
              <el-date-picker v-model="baseInfoForm.enterFactoryDate" placeholder="请选择进厂日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="使用部门" prop="useSector">
              <el-input v-model="baseInfoForm.useSector" placeholder="请输入使用部门" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="baseInfoForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="baseInfoDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="baseInfoFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="设施名称">
        <el-input v-model="searchForm.deviceName" placeholder="请输入设施名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="baseInfoPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="deviceName" label="设施名称" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column prop="manufacturer" label="生产厂家" />
      <el-table-column prop="serialNumber" label="出厂编号" />
      <el-table-column prop="deviceNo" label="厂内编号" />
      <el-table-column label="进厂日期">
        <template slot-scope="scope">
          <span v-if="scope.row.enterFactoryDate">{{ scope.row.enterFactoryDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="useSector" label="使用部门" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="register" label="登记人" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="baseInfoPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addBaseInfo,
  deleteBaseInfo,
  updateBaseInfo,
  selectBaseInfoInfo,
  selectBaseInfoList
} from '@/api/device/baseInfo'

export default {
  data () {
    return {
      baseInfoDialogVisible: false,
      baseInfoFormTitle: '',
      baseInfoForm: {
        id: '',
        deviceName: '',
        spec: '',
        manufacturer: '',
        serialNumber: '',
        deviceNo: '',
        enterFactoryDate: '',
        useSector: '',
        remarks: ''
      },
      baseInfoFormRules: {
        deviceName: [{ required: true, message: '设施名称不能为空', trigger: ['blur', 'change']}]
      },
      baseInfoPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceName: ''
      }
    }
  },
  created () {
    selectBaseInfoList(this.searchForm).then(res => {
      this.baseInfoPage = res
    })
  },
  methods: {
    baseInfoDialogClose () {
      this.$refs.baseInfoFormRef.resetFields()
    },
    baseInfoFormSubmit () {
      if (this.baseInfoFormTitle === '基础设施台帐详情') {
        this.baseInfoDialogVisible = false
        return
      }
      this.$refs.baseInfoFormRef.validate(async valid => {
        if (valid) {
          if (this.baseInfoFormTitle === '新增基础设施台帐') {
            await addBaseInfo(this.baseInfoForm)
          } else if (this.baseInfoFormTitle === '修改基础设施台帐') {
            await updateBaseInfo(this.baseInfoForm)
          }
          this.baseInfoPage = await selectBaseInfoList(this.searchForm)
          this.baseInfoDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.baseInfoFormTitle = '新增基础设施台帐'
      this.baseInfoDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteBaseInfo(row.id)
        if (this.baseInfoPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.baseInfoPage = await selectBaseInfoList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.baseInfoFormTitle = '修改基础设施台帐'
      this.baseInfoDialogVisible = true
      this.selectBaseInfoInfoById(row.id)
    },
    handleInfo (index, row) {
      this.baseInfoFormTitle = '基础设施台帐详情'
      this.baseInfoDialogVisible = true
      this.selectBaseInfoInfoById(row.id)
    },
    selectBaseInfoInfoById (id) {
      selectBaseInfoInfo(id).then(res => {
        this.baseInfoForm.id = res.id
        this.baseInfoForm.deviceName = res.deviceName
        this.baseInfoForm.spec = res.spec
        this.baseInfoForm.manufacturer = res.manufacturer
        this.baseInfoForm.serialNumber = res.serialNumber
        this.baseInfoForm.deviceNo = res.deviceNo
        this.baseInfoForm.enterFactoryDate = res.enterFactoryDate
        this.baseInfoForm.useSector = res.useSector
        this.baseInfoForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectBaseInfoList(this.searchForm).then(res => {
        this.baseInfoPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectBaseInfoList(this.searchForm).then(res => {
        this.baseInfoPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectBaseInfoList(this.searchForm).then(res => {
        this.baseInfoPage = res
      })
    }
  }
}
</script>

<style>
</style>
